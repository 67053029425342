import { render, staticRenderFns } from "./managecommuters.vue?vue&type=template&id=6f06f8fa&scoped=true&"
import script from "./managecommuters.vue?vue&type=script&lang=js&"
export * from "./managecommuters.vue?vue&type=script&lang=js&"
import style0 from "./managecommuters.vue?vue&type=style&index=0&id=6f06f8fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f06f8fa",
  null
  
)

export default component.exports