<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FuelManager'"
      :ActiveMenu="'FuelOperations'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          :pageheaderTitle="'Upload Fuel Transactions'"
          pageheaderdescription=""
          breadcrumbitem1="Home"
          breadcrumbitem2="Fuel Manager"
          breadcrumbitem3="Transactions"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

      

           <!-- begin row -->
           <div class="row">
              <!-- begin col-3 -->
          <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-content">
                <div class="stats-title">TOTAL REBATE</div>
                <div class="stats-number">{{(GETDASHBOARDCARDS.lrp93rebates + GETDASHBOARDCARDS.unleaded93rebates + GETDASHBOARDCARDS.unleaded95rebates +
                GETDASHBOARDCARDS.diesel50rebates + GETDASHBOARDCARDS.diesel500rebates) | currencywithSymbol}}</div>

<div class="stats-desc" >Litres: {{(GETDASHBOARDCARDS.lrp93litres + GETDASHBOARDCARDS.unleaded93litres + GETDASHBOARDCARDS.unleaded95litres
                + GETDASHBOARDCARDS.diesel50litres + GETDASHBOARDCARDS.diesel500litres) | Round}} </div>
<div class="stats-desc" >Price: {{(GETDASHBOARDCARDS.lrp93price + GETDASHBOARDCARDS.unleaded93price + 
                  GETDASHBOARDCARDS.unleaded95price + GETDASHBOARDCARDS.diesel50price + 
                  + GETDASHBOARDCARDS.diesel500price) | currencywithSymbol}}</div>

                <!-- <div class="stats-desc">{{(GETDASHBOARDCARDS.lrp93litres + GETDASHBOARDCARDS.unleaded93litres + 
                 GETDASHBOARDCARDS.diesel50litres + GETDASHBOARDCARDS.diesel500litres) | Round}} 
                  Litres And Price {{(GETDASHBOARDCARDS.unleaded93price + 
                  GETDASHBOARDCARDS.unleaded95price + GETDASHBOARDCARDS.diesel50price + GETDASHBOARDCARDS.lrp93price +
                  + GETDASHBOARDCARDS.diesel500price) | currencywithSymbol}}</div> -->
              </div>
            </div>
          </div>
          <!-- end col-3 -->
            <!-- begin col-3 -->
          <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-content">
                <div class="stats-title">REBATE - LRP 93</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.lrp93rebates | currencywithSymbol}}</div>

                <div class="stats-desc" v-if="GETDASHBOARDCARDS.lrp93litres" >Litres: {{GETDASHBOARDCARDS.lrp93litres | Round}}</div>
                <div class="stats-desc" v-if="GETDASHBOARDCARDS.lrp93price" >Price: {{GETDASHBOARDCARDS.lrp93price | currencywithSymbol}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">REBATE - UNLEADED 93</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.unleaded93rebates | currencywithSymbol}}</div>

                <div class="stats-desc" v-if="GETDASHBOARDCARDS.unleaded93litres" >Litres: {{GETDASHBOARDCARDS.unleaded93litres | Round}}</div>
                <div class="stats-desc" v-if="GETDASHBOARDCARDS.unleaded93price" >Price: {{GETDASHBOARDCARDS.unleaded93price | currencywithSymbol}}</div>
             
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">REBATE - UNLEADED 95</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.unleaded95rebates | currencywithSymbol}}</div>

                <div class="stats-desc" v-if="GETDASHBOARDCARDS.unleaded95litres" >Litres: {{GETDASHBOARDCARDS.unleaded95litres | Round}}</div>
                <div class="stats-desc" v-if="GETDASHBOARDCARDS.unleaded95price" >Price: {{GETDASHBOARDCARDS.unleaded95price | currencywithSymbol}}</div>

              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">REBATE - 50 PPM DIESEL</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.diesel50rebates | currencywithSymbol}}</div>

                <div class="stats-desc" v-if="GETDASHBOARDCARDS.diesel50litres" >Litres: {{GETDASHBOARDCARDS.diesel50litres | Round}}</div>
                <div class="stats-desc" v-if="GETDASHBOARDCARDS.diesel50price" >Price: {{GETDASHBOARDCARDS.diesel50price | currencywithSymbol}}</div>

              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">REBATE - 500 PPM DIESEL</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.diesel500rebates | currencywithSymbol}}</div>

                <div class="stats-desc" v-if="GETDASHBOARDCARDS.diesel500litres" >Litres: {{GETDASHBOARDCARDS.diesel500litres | Round}}</div>
                <div class="stats-desc" v-if="GETDASHBOARDCARDS.diesel500price" >Price: {{GETDASHBOARDCARDS.diesel500price | currencywithSymbol}}</div>

              </div>
            </div>
          </div>
          <!-- end col-3 -->
         
           
        </div>

        <!-- end row -->

             <!-- begin row -->
             <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-info-circle"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL FUEL TRANSACTIONS</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltransactions | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fa fa-solid fa-times-circle"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL VEHICLES NOT IN SYSTEM</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalvehiclenotinsystem | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fa fa-solid fa-clone"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL DUPLICATES</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalduplicates | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
            <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-check"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL CLEARED TRANSACTIONS</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.transtobeadded | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
         
           
        </div>

        <!-- end row -->

        

          <!-- begin row -->
          <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">FUEL TRANSACTIONS </h4>
              </div>
              <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  class="btn btn-white"
                  href="javascript:;"
                  v-on:click="AddModalOpen"
                >
                  <i class="fa fa-plus"></i>
                  Upload File
                </a>
                <button
                          id="btnSubmitUploadedfile"
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="SubmitFileUploaded()"
                          :disabled="$wait.is('upload') || GETALLTRANSACTIONS.length == 0"
                        >
                          <v-wait v-bind:for="'upload'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Submit
                          </v-wait>
                        </button>
              </div>
            </div>
              <div class="panel-body">
                <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th></th>
                  <th>DATE</th>
                  <th>FILE OPERATOR</th>
                  <th>OPERATOR</th>
                  <th>REG NO</th>
                  <th>FILE REG NO</th>
                  <th>FUEL TYPE</th>
                  <th>FUEL VALUE</th>
                  <th>LITRES</th>
                  <th>REBATE</th>
                  <th>INFO</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETALLTRANSACTIONS"
                    v-bind:key="item.id"
                    :class="{
                    'danger': !item.vehicleid || item.isduplicate,
                    }"
                >
                <td>{{++index}}</td>
                <td data-toggle="tooltip" data-placement="top" title="Tooltip on top">{{item.transdate | formatToShortDateWithTime}}</td>
                <td>{{item.operators}}</td>
                <td>{{item.orgoperator}}</td>
                <td>{{item.regno}}</td>
                <td>{{item.orgregno}}</td>
                <td>{{item.fueltype}}</td>
                <td>{{item.fuelprice | newcurrencywithSymbol}}</td>
                <td>{{item.numoflitres}}</td>
                <td>{{item.rebatefee | newcurrencywithSymbol}}</td>
                <td>
                  <span class="badge bg-warning"  v-if="item.isoperatormismatch"> OPERATORS DON'T MATCH  </span>
                  <span class="badge bg-danger"  v-if="!item.vehicleid"> VEHICLE NOT EXISTS </span>
                    <span class="badge bg-danger" v-if="item.isduplicate"> DUPLICATE TRANSACTION </span>
                    <span class="badge bg-success" v-if="item.vehicleid && !item.isduplicate && !item.isoperatormismatch"> ALL CLEAR </span>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
              </div>
            </div>
            <!-- end panel -->
          </div>
        </div>
        <!-- end row -->


          <!-- Add Modal HTML START-->
  <div id="AddModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Upload File</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          v-show="DROPZONEVISIBLE"
                          :acceptedFiles="acceptedFiles"
                          :ClearData="false"
                          :maxFiles=1
                          v-on:fileadded="fileadded"
                          :SessionID="documentsessionid"
                        ></DropZoneControl>
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th>Column</th>
                                <th>Value</th>
                                <th>Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, name,index) in GETUPLOADEDFILESMATCH[0]" :key="name">
                                <td><b>{{name}}</b></td>
                                <td>{{item}}</td>
                                <td>
                                  <span v-bind:id="index + '-span'" ></span>
                                  <select
                                    v-bind:id="index + '-select'"
                                    class="form-control"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    @change="onChangeColumn($event, item, name,columntype,index)"
                                    v-model.lazy="columntype"
                                  >
                                    <option
                                      v-bind:key="item.id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item.id"
                                    >{{item.name}}</option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="SubmitFileType()"
                          :disabled="$wait.is('add') || GETALLTRANSACTIONS.length > 0"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Submit
                          </v-wait>
                        </button>
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->


      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "fuelmanager_dashboard"
);

export default {
  name: "UPLOADFUELTRANSACTIONS",
  data: function() {
    return {
      acceptedFiles: "text/csv",
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      rankid: this.$route.params.id,
      rankname: this.$route.params.desc,
      GETALLTRANSACTIONS:[],
      GETALLVEHICLESTRANSACTIONS:[],
      GETROUTESTATS: {},
      GETOPERATORFINSTATSTOP5: {},
      GETQEUEUEDDETAILS: {},
      GETBOARDQEUEUEDDETAILS: {},
      GETTRIPSDETAILS: {},
      GETMOSTTRIPSTOP5DETAILS: {},
      GETMOSTLEASTTOP5DETAILS: {},
      startDate:null,
      endDate:null,
      GETALLROUTES: {},
      ROUTEID: {},
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      paymentsloaded: false,
      documentsessionid: this.$uuid.v4(),
      GETUPLOADEDFILES: [],
      GETUPLOADEDFILESMATCH: [],
      GETALLDOCUMENTTYPE: [],
      columntype: 0,
      DROPZONEVISIBLE: true,
      COLUMNTYPE: {
        "DATE": "",
        "REGNO": "",
        "FUELTYPE": "",
        "FUELVALUE": "",
        "LITRES": "",
      },
      GETDASHBOARDCARDS: {
        lrp93rebates:0,
        lrp93litres:0,
        lrp93price:0,
        unleaded93rebates:0,
        unleaded93litres:0,
        unleaded93price:0,
        unleaded95rebates:0,
        unleaded95litres:0,
        unleaded95price:0,
        diesel50rebates:0,
        diesel50litres:0,
        diesel50price:0,
        diesel500rebates:0,
        diesel500litres:0,
        diesel500price:0,
        totaltransactions:0,
        totalvehiclenotinsystem:0,
        totalduplicates:0,
        totalnewtrans:0,
        transtobeadded:0,
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  computed: {
    
  },
  methods: {
    ...mapActions(["getfuelmanageropsdashboardcards", "getuploadfiledata","getoperationstransactions","getprocessuploadfiledata","submitprocessuploadfiledata"]),
    AddModalOpen: () => {
      const context = $(document).data("context");
      context.DROPZONEVISIBLE = true;
      $('#AddModal').modal({
      backdrop: 'static',
      keyboard: false
    });
    },
    LOADCARDS:  (context) => {
      for (var i = 0; i < context.GETALLTRANSACTIONS.length; i++) {
        if (context.GETALLTRANSACTIONS[i].fueltypecode == "50PPM") {
          context.GETDASHBOARDCARDS.diesel50rebates += context.GETALLTRANSACTIONS[i].rebatefee;
          context.GETDASHBOARDCARDS.diesel50litres += context.GETALLTRANSACTIONS[i].numoflitres;
          context.GETDASHBOARDCARDS.diesel50price += context.GETALLTRANSACTIONS[i].fuelprice;
        }
        if (context.GETALLTRANSACTIONS[i].fueltypecode == "500PPM") {
          context.GETDASHBOARDCARDS.diesel500rebates += context.GETALLTRANSACTIONS[i].rebatefee;
          context.GETDASHBOARDCARDS.diesel500litres += context.GETALLTRANSACTIONS[i].numoflitres;
          context.GETDASHBOARDCARDS.diesel500price += context.GETALLTRANSACTIONS[i].fuelprice;
        }
        if (context.GETALLTRANSACTIONS[i].fueltypecode == "ULP93") {
          context.GETDASHBOARDCARDS.unleaded93rebates += context.GETALLTRANSACTIONS[i].rebatefee;
          context.GETDASHBOARDCARDS.unleaded93litres += context.GETALLTRANSACTIONS[i].numoflitres;
          context.GETDASHBOARDCARDS.unleaded93price += context.GETALLTRANSACTIONS[i].fuelprice;
        }
        if (context.GETALLTRANSACTIONS[i].fueltypecode == "ULP95") {
          context.GETDASHBOARDCARDS.unleaded95rebates += context.GETALLTRANSACTIONS[i].rebatefee;
          context.GETDASHBOARDCARDS.unleaded95litres += context.GETALLTRANSACTIONS[i].numoflitres;
          context.GETDASHBOARDCARDS.unleaded95price += context.GETALLTRANSACTIONS[i].fuelprice;
        }
        if (context.GETALLTRANSACTIONS[i].fueltypecode == "LRP93") {
          context.GETDASHBOARDCARDS.lrp93rebates += context.GETALLTRANSACTIONS[i].rebatefee;
          context.GETDASHBOARDCARDS.lrp93litres += context.GETALLTRANSACTIONS[i].numoflitres;
          context.GETDASHBOARDCARDS.lrp93price += context.GETALLTRANSACTIONS[i].fuelprice;
        }

        context.GETDASHBOARDCARDS.totaltransactions += context.GETALLTRANSACTIONS[i].fuelprice;
        if (!context.GETALLTRANSACTIONS[i].vehicleid) {
          context.GETDASHBOARDCARDS.totalvehiclenotinsystem += context.GETALLTRANSACTIONS[i].fuelprice;
        }
        if (context.GETALLTRANSACTIONS[i].isduplicate) {
            context.GETDASHBOARDCARDS.totalduplicates += context.GETALLTRANSACTIONS[i].fuelprice;
        }
        if (!context.GETALLTRANSACTIONS[i].isduplicate && context.GETALLTRANSACTIONS[i].vehicleid) {
            context.GETDASHBOARDCARDS.transtobeadded += context.GETALLTRANSACTIONS[i].fuelprice;
        }

        

          context.GETDASHBOARDCARDS.totalnewtrans = context.GETDASHBOARDCARDS.totaltransactions - context.GETDASHBOARDCARDS.totalduplicates
        
      }
    },
    ClearData: (context) => {
    },
    SubmitFileType: () => {
      const context = $(document).data("context");
      context.$wait.start("add");

      const payload = {
        taxiassociationsid: context.taxiassociationid,
        sessionid: context.documentsessionid,
        regnocolumn: context.COLUMNTYPE.REGNO,
        fueltypecolumn: context.COLUMNTYPE.FUELTYPE,
        fueltransdatecolumn: context.COLUMNTYPE.DATE,
        fuelpricecolumn: context.COLUMNTYPE.FUELVALUE,
        fuellitrescolumn: context.COLUMNTYPE.LITRES,
      }

      context.getprocessuploadfiledata({ router: context.$router, payload })
      .then(parsedResultOP => {

        context.GETALLTRANSACTIONS = parsedResultOP;
        $('[data-toggle="tooltip"]').tooltip();
        context.LOADCARDS(context);
        
      })
      .catch(error => {
       
      }).finally(() => {

        context.$wait.end("add");
        $('#AddModal').modal('hide');
           
           var options = {
                   dom: "lBfrtip",
                   buttons: [
                     { extend: "copy", className: "btn-sm" },
                     { extend: "csv", className: "btn-sm" },
                     { extend: "excel", className: "btn-sm" },
                     { extend: "pdf", className: "btn-sm" },
                     { extend: "print", className: "btn-sm" }
                   ],
                   responsive: false,
                   autoFill: false,
                   colReorder: true,
                   keys: false,
                   rowReorder: false,
                   select: false
                 };

                 if ($(window).width() <= 767) {
                   options.rowReorder = false;
                   options.colReorder = false;
                   options.autoFill = false;
                 }

                
                 if(!context.paymentsloaded){
                   $(".table-invoice").DataTable(options);
                 }

                 context.paymentsloaded = true;

         });

    },
    SubmitFileUploaded: () => {
      const context = $(document).data("context");
      context.$wait.start("upload");

      const payload = {
        taxiassociationsid: context.taxiassociationid,
        transactiondata: JSON.stringify(context.GETALLTRANSACTIONS),
        added_by: context.USERID,
      }

      context.submitprocessuploadfiledata({ router: context.$router, payload })
      .then(parsedResultOP => {

        if (parsedResultOP.status) {
          context.$Notify.Success("Successfully Added!", "");
          context.GETALLTRANSACTIONS = [];
        } else {
          context.$Notify.Error("Error Adding!", "");
        }
        
      })
      .catch(error => {
       
      }).finally(() => {
        context.$wait.end("upload");
      })

    },
    onChangeColumn: ($event, item, name,columntype,index) => {
      const context = $(document).data("context");

      var nametype;
      //DATE
      if(columntype == 1){
        nametype = "DATE"
        debugger
        context.COLUMNTYPE.DATE = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //REGNO
      if(columntype == 2){
        nametype = "REGNO"
        context.COLUMNTYPE.REGNO = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //FUELTYPE
      if(columntype == 3){
        nametype = "FUELTYPE"
        context.COLUMNTYPE.FUELTYPE = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //FUELVALUE
      if(columntype == 4){
        nametype = "FUELVALUE"
        context.COLUMNTYPE.FUELVALUE = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //LITRES
      if(columntype == 5){
        nametype = "LITRES"
        context.COLUMNTYPE.LITRES = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

     

      //console.log(JSON.stringify(context.COLUMNTYPE));
    },
    fileadded: (data) => {
      const context = $(document).data("context");
      context.GETUPLOADEDFILES = [];
      context.GETUPLOADEDFILES.push({
        filename: data.name,
        mimetype: data.type,
        fileId: data.fileId,
      });

      const payload = {
        sessionid: context.documentsessionid,
        getfirstrow: true 
      }

      context.getuploadfiledata({ router: context.$router, payload })
      .then(parsedResultOP => {
        context.GETUPLOADEDFILESMATCH = parsedResultOP;

        context.DROPZONEVISIBLE = false;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

    },
    init: function() {},
    Delete: (context,item, id, deletedby) => {
      
      context.$Notify.YesNo(false, { context, item,id, deletedby }).then((value) => {
        if (value) {
          // value.context.$wait.start("delete-" + value.id);

          // var router = value.context.$router;

          //  var payload = {
          //     tripid: value.id,
          //     userid: value.deletedby,
          //   };

          // value.context.markAsDeleted({ router, payload })
          //   .then(() => {
          //     //value.context.GETVEHICLEBYASSID = value.context.GETVEHICLEBYASSID.filter(item => item._taxi_operator_vehicles_id !== value.id);
          //     value.context.$wait.end("delete-" + value.id);
          
          //     value.context.$Notify.Success("Successfully Deleted!", "");
               value.item.date_deleted = value.id;
          //   })
          //   .catch((error) => {
          //     value.context.$wait.end("delete-" + value.id);
          //     value.context.$Notify.Error(error.data[0].message, "");
          //   })
          //   .finally(() => {});
        }
      });
    },
    onChange(context) {

      const router = context.$router;

      var payload = {
          taxiassociationsId: context.USERPROFILE.taxi_associations_id,
          startDate: context.startDate,
          endDate:context.endDate,
        };

    context.getfuelmanageropsdashboardcards({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      context.getoperationstransactions({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETALLTRANSACTIONS = parsedResultOP.alltripsdata;
        context.GETALLVEHICLESTRANSACTIONS = parsedResultOP.allvehicles;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
           
           var options = {
                   dom: "lBfrtip",
                   buttons: [
                     { extend: "copy", className: "btn-sm" },
                     { extend: "csv", className: "btn-sm" },
                     { extend: "excel", className: "btn-sm" },
                     { extend: "pdf", className: "btn-sm" },
                     { extend: "print", className: "btn-sm" }
                   ],
                   responsive: false,
                   autoFill: false,
                   colReorder: true,
                   keys: false,
                   rowReorder: false,
                   select: false
                 };

                 if ($(window).width() <= 767) {
                   options.rowReorder = false;
                   options.colReorder = false;
                   options.autoFill = false;
                 }

                
                 if(!context.paymentsloaded){
                   $(".table-invoice").DataTable(options);
                 }

                 context.paymentsloaded = true;

         });

    }
  },
  mounted: function() {
    $(document).data("context", this);

    App.init();
    
    this.GETALLDOCUMENTTYPE = [{
      id: 1,
      name: "DATE",
    },
    {
      id: 2,
      name: "REG NO",
    },
    {
      id: 3,
      name: "FUEL TYPE",
    },
    {
      id: 4,
      name: "FUEL VALUE",
    },
    {
      id: 5,
      name: "LITRES",
    },
  ];
   
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>