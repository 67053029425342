<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'SanralServices'"
      :ActiveMenu="'ViewCommuters'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Sanral Customer Profile"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Sanral"
        breadcrumbitem3="Commuter Profile"
      />
      <!-- end page-header -->
      <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-2 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">NO OF CARDS</div>
              <div class="stats-number">{{GETCOMMUTERBYID.numberofvehicles }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
          <!-- begin col-3 -->
        <div class="col-lg-2 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL BALANCE</div>
              <div class="stats-number" :class="GETCOMMUTERBYID.balance < 0 ? 'text-danger' : 'even'">{{GETCOMMUTERBYID.balance | newcurrencywithSymbol}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
           <!-- begin col-3 -->
        <div class="col-lg-2 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL DEPOSITS</div>
              <div class="stats-number">{{GETCOMMUTERBYID.totaldeposits | newcurrencywithSymbol}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
            <!-- begin col-3 -->
        <div class="col-lg-2 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL PURCHASES</div>
              <div class="stats-number">{{GETCOMMUTERBYID.totalpurchases | newcurrencywithSymbol}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
            <!-- begin col-3 -->
        <div class="col-lg-2 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL FEES</div>
              <div class="stats-number">{{GETCOMMUTERBYID.totalfees | newcurrencywithSymbol}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <!-- <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">AVAILABLE BALANCE</div>

              <div
                class="stats-number"
              ></div>
            </div>
          </div>
        </div> -->
        <!-- end col-3 -->
      </div>

      <!-- begin #Main Body Template -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <div class="panel panel-inverse">
            <!-- begin fieldset -->
            <fieldset>
              <p class="text-center h4 mb-4">Customer Details</p>
              <div class="row">
                <!-- begin col-6 -->

                <!-- begin form-group -->
                <div class="col-lg-6">

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Firstname</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GETCOMMUTERBYID.firstname"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Surname</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GETCOMMUTERBYID.surname"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Mobile Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GETCOMMUTERBYID.mobile_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                   <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Id Number/Passport</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GETCOMMUTERBYID.idnumber"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>

                <!-- Begin Second Row -->
                <div class="col-lg-6">
                  <!-- begin form-group -->
                  
                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Type</label>
                    <div class="col-md-6">
                      <div class="switcher switcher-success">
                      <span
                      class="badge f-s-12"
                        v-bind:class="{
                          'badge-warning': !GETCOMMUTERBYID.isprepaid,
                          'badge-success': GETCOMMUTERBYID.isprepaid,
                        }"
                        >{{
                          GETCOMMUTERBYID.isprepaid == true ? "Prepaid" : "Post Paid"
                        }}</span
                      >
                      </div>
                    </div>
                  </div>
                  <!-- End form-group -->
                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Status</label>
                    <div class="col-md-6">
                      <div class="switcher switcher-success">
                        <span
                        class="badge f-s-12"
                        v-bind:class="{
                          'badge-danger': GETCOMMUTERBYID.is_suspendaccount,
                          'badge-success': !GETCOMMUTERBYID.is_suspendaccount,
                        }"
                        >{{
                          GETCOMMUTERBYID.is_suspendaccount == true
                            ? "Suspended"
                            : "Active"
                        }}</span
                      >
                      </div>
                    </div>
                  </div>
                  <!-- End form-group -->

                </div>
              </div>
            </fieldset>
            <!-- end fieldset -->
          </div>
        </div>
        <!-- end col-12 -->
      </div>
      <div class="btn-row">
        <div class="text-center">
          <button
                  type="button"
                  class="btn btn-default width-100 m-5"
                  v-show="USERPROFILE.dialeragentuserid && GETCOMMUTERBYID.mobile_number"
                  @click="CALL(context)"
                  :disabled="$wait.is('Call')"
                >
                  <v-wait v-bind:for="'Call'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Call
                  </v-wait>
          </button>
        </div>
      </div>
      <br />

      <!-- begin panel -->
      <div class="panel panel-inverse panel-with-tabs" data-sortable-id="ui-unlimited-tabs-2">
        <!-- begin panel-heading -->
        <div class="panel-heading p-0">
          <div class="panel-heading-btn m-r-10 m-t-10">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-inverse"
              data-click="panel-expand"
            >
              <i class="fa fa-expand"></i>
            </a>
          </div>
          <!-- begin nav-tabs -->
          <div class="tab-overflow">
            <ul class="nav nav-tabs nav-tabs-inverse">
              <li class="nav-item prev-button">
                <a href="javascript:;" data-click="prev-tab" class="text-inverse nav-link">
                  <i class="fa fa-arrow-left"></i>
                </a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-1" data-toggle="tab" class="nav-link">Vehicles</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-2" data-toggle="tab" class="nav-link">Contacts</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-3" data-toggle="tab" class="nav-link">Transactions</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-4" data-toggle="tab" class="nav-link">Tolls</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-5" data-toggle="tab" class="nav-link">Fees</a>
              </li>
              
              <li class="nav-item next-button">
                <a href="javascript:;" data-click="next-tab" class="text-inverse nav-link">
                  <i class="fa fa-arrow-right"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- end nav-tabs -->
        </div>
        <!-- end panel-heading -->
        <!-- begin tab-content -->
        <div class="tab-content">
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-1">
            <!-- Start panel-body -->
            <div class="panel-toolbar">
              <div class="btn-group m-r-5">

                <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>
                  Add Vehicle
                </a>
              </div>
            </div>
               
                  <button
                  type="button"
                  class="btn btn-sm btn-danger width-100"
                  v-if="ISVIEWTRANSACTIONS"
                  @click="ClickViewCards(context)"
                >View Cards</button>
              </div>
            </div>
            <div class="panel-body">
              <table id="data-table-defaultcard" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">Reg No</th>
                  <th class="text-nowrap">Type</th>
                  <th class="text-nowrap">Class</th>
                  <th class="text-nowrap">Status</th>
                  <th class="text-nowrap">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in GETVEHICLESLIST"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                  <td>{{item.regnumber}}</td>
                  <td>{{item.vehicle_type}}</td>
                  <td>{{item.vehicleclass}}</td>
                  <td>{{item.status}}</td>
                  <td>
                    <span
                      class="badge f-s-12"
                      v-bind:class="{ 'badge-success': !item.blocked, 'badge-danger':item.blocked  }"
                    >{{!item.blocked ? 'Active' : 'Blocked' }}</span>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-sm btn-danger width-80"
                      v-if="!item.blocked"
                      @click="ClickBlockCard(context,item)"
                    >Block</button>
                    <button
                      type="button"
                      class="btn btn-sm btn-success width-80"
                      v-if="item.blocked"
                      @click="ClickUnBlockCard(context,item)"
                    >UnBlocked</button>
                    <button
                      type="button"
                      class="btn btn-sm btn-danger width-80 hide"
                      v-if="!item.blocked"
                      @click="DeleteCard(context,item)"
                    >Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
           <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-2">
            <!-- Start panel-body -->
            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModalContact"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>
                  Add Mobile
                </a>
              </div>
              <div class="btn-group m-r-5">
               
                  <button
                  type="button"
                  class="btn btn-sm btn-danger width-100"
                  v-if="ISVIEWTRANSACTIONS"
                  @click="ClickViewCards(context)"
                >View Cards</button>
              </div>
            </div>
            <div class="panel-body">
              <table id="data-table-defaultcard" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">Mobile</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in GETCONTACTSLIST"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                  <td>{{item.mobileno}}</td>
                </tr>
              </tbody>
            </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
           <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-3">
            <!-- Start panel-body -->
            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
               
                  <button
                  type="button"
                  class="btn btn-sm btn-danger width-100"
                  v-if="ISVIEWTRANSACTIONS"
                  @click="ClickViewCards(context)"
                >View Cards</button>
              </div>
            </div>
            <div class="panel-body">
              <table id="data-table-trans" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">ETAG</th>
                  <th class="text-nowrap">DATE</th>
                  <th class="text-nowrap">TYPE</th>
                  <th class="text-nowrap">AMOUNT</th>
                  <th class="text-nowrap">BALANCE</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in GETTRANSACTIONLISTS"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                  <td>{{item.etagno}}</td>
                  <td>{{item.transactiondate | formatToShortDateWithTime}}</td>
                  <td v-if="item.transaction_type_id===3">{{item.transactionreference}}</td>
                  <td v-if="item.transaction_type_id===2">Toll</td>
                  <td v-if="item.transaction_type_id===1">{{item.transaction_typesname}}</td>
                  <td :class="item.transactionamount < 0 ? 'text-danger' : 'text-success'">{{item.transactionamount | currencywithSymbol}}</td>
                  <td>{{item.remainingbalance  | currencywithSymbol}}</td>
                </tr>
              </tbody>
            </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
           <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-4">
            <!-- Start panel-body -->
            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
               
                  <button
                  type="button"
                  class="btn btn-sm btn-danger width-100"
                  v-if="ISVIEWTRANSACTIONS"
                  @click="ClickViewCards(context)"
                >View Cards</button>
              </div>
            </div>
            <div class="panel-body">
              <table id="data-table-tolltrans" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">DATE</th>
                  <th class="text-nowrap">TYPE</th>
                  <th class="text-nowrap">REG NO</th>
                  <th class="text-nowrap">AGENCY</th>
                  <th class="text-nowrap">DESC</th>
                  <th class="text-nowrap">CLASS</th>
                  <th class="text-nowrap">ETAG</th>
                  <th class="text-nowrap">AMOUNT</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in GETTOLLSTRANS"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                  <td>{{item.transactiondate | formatToShortDateWithTime}}</td>
                  <td>{{item.transtype}}</td>
                  <td>{{item.regnumber}}</td>
                  <td>{{item.agency}}</td>
                  <td>{{item.description}}</td>
                  <td>{{item.vehicleclass}}</td>
                  <td>{{item.etagno}}</td>
                  <td>{{item.netamount | currencywithSymbol}}</td>
                </tr>
              </tbody>
            </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
            <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-5">
            <!-- Start panel-body -->
            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
               
                  <button
                  type="button"
                  class="btn btn-sm btn-danger width-100"
                  v-if="ISVIEWTRANSACTIONS"
                  @click="ClickViewCards(context)"
                >View Cards</button>
              </div>
            </div>
            <div class="panel-body">
              <table id="data-table-transetolls" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">DATE</th>
                  <th class="text-nowrap">TYPE</th>
                  <th class="text-nowrap">AMOUNT</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in GETTOLLSFEESCARDS"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                  <td>{{item.transactiondate | formatToShortDateWithTime}}</td>
                  <td>{{item.transtype}}</td>
                  <td>{{item.transactionamount | currencywithSymbol}}</td>
                </tr>
              </tbody>
            </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
        </div>
        <!-- end tab-content -->
      </div>
      <!-- end panel -->

        <!-- Add Modal HTML START-->
  <div id="AddModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Vehicle</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Reg No</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Reg No"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED.regno"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                            <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">eTag No</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter eTag No"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED.etagno"
                              >
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="addVehicle(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->

                <!-- Add Modal HTML START-->
  <div id="AddModalContact" class="modal fade" v-if="GETSELECTEDMOBILE">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Mobile NO</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Mobile No</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Mobile Number"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTEDMOBILE.mobile"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                          
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="addMobile(context,GETSELECTEDMOBILE,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("sanraletag");

export default {
  name: "COMMUTERPROFILE",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      accountNumber: "",
      id: this.$route.params.id,
      GETUPLOADEDFILES: [],
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      skip: 0,
      len: 1000,
      GETVEHICLESLIST: [],
      GETCONTACTSLIST: [],
      GETTRANSACTIONLISTS: [],
      GETTOLLSTRANS: [],
      GETTOLLSFEESCARDS: [],
      GETCOMMUTERVIPCARDS: [],
      ISVIEWTRANSACTIONS:false,
      GETCOMMUTERCARDTRANSACTIONS: [],
      GETCOMMUTERBYID: {},
      GETCOMMUTERCARDBYID: {
        cardinventory_id: 0,
        id: "",
        cardnumber: "",
        is_active: false,
        blocked: false,
        balance: 0
      },
      GETSELECTED: {
        sanral_customeraccount_id: "",
        regno: "",
        etagno: "", 
      },
      GETSELECTEDMOBILE: {
        sanral_customeraccount_id: "",
        mobile: "",
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl
  },

  computed: {
    ...mapGetters(["GETCOMMUTERROUTES"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getbyid"
    ]),
    CALL: (context) => {
      context.$Notify
        .YesNo(false, {
          context,
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("Call" + value.item.commuters_id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TO/callOperator", {
                router: context.$router,
                agentno: value.context.USERPROFILE.dialeragentuserid,
                mobileno: value.context.GETCOMMUTERBYID.mobile_number,
              })
              .then(() => {
                //value.context.$wait.end("Call" + value.item.commuters_id);
                value.context.$Notify.Success("Successfull", "");
              })
              .catch((error) => {
                //value.context.$wait.end("Call");
                //value.context.$Notify.Error(error.data[0].message, "");
                //value.context.$wait.end("Call");
                value.context.$Notify.Success("Successfull", "");
              })
              .finally(() => {
                value.context.$wait.end("Call" + value.item.commuters_id);
              });
          }
        });
    },
    addMobile: (context, item, addedby) => {
      item.addedby = addedby;

      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
          .dispatch("sanraletag/addMobile", {
              router: value.context.$router,
              payload: {
              sanral_customeraccount_id: parseInt(value.context.id),
              mobileno: value.item.mobile,
              addedby: value.item.addedby,
              }
            })
            .then(() => {
              $("#AddModalContact").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
              value.context.getOtherContactsById(value.context, value.context.id);
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    addVehicle: (context, item, addedby) => {
      item.addedby = addedby;

      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
          .dispatch("sanraletag/AddVehicle", {
              router: value.context.$router,
              payload: {
              sanral_customeraccount_id: value.context.id,
              regnumber: value.item.regno,
              etagno: value.item.etagno,
              addedby: value.item.addedby,
              }
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
              value.context.getVehiclesById(value.context, value.context.id);
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')

      const context = $("#content").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },

    getbyid: (context, id) => {
      context.$store
        .dispatch("sanraletag/getSanralCustomersById", {
          router: context.$router,
          id
        })
        .then(data => {

          context.GETCOMMUTERBYID = data[0]
          console.log("this is the commuter: ", data);
         // context.getCommuterCardById(context,context.id);
          
        })
        .catch(err => {
          console.log("commuter details error: ", err);
        });
    },
    getSanralfeesById: (context, id) => {
      context.$store
        .dispatch("sanraletag/getSanralfeesById", {
          router: context.$router,
          id
        })
        .then(data => {

          context.GETTOLLSFEESCARDS = data;
         // context.getCommuterCardById(context,context.id);
          
        })
        .catch(err => {
          console.log("commuter details error: ", err);
        });
    },
    getSanralEtollsById: (context, id) => {
      context.$store
        .dispatch("sanraletag/getSanralEtollsById", {
          router: context.$router,
          id
        })
        .then(data => {

          context.GETTOLLSTRANS = data;
         // context.getCommuterCardById(context,context.id);
          
        })
        .catch(err => {
          console.log("commuter details error: ", err);
        }).finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true,
                    order: [[1, 'desc']]
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }


        $("#data-table-tolltrans").DataTable(options);
        });
    },
    getSanralTransactionsById: (context, id) => {
      context.$store
        .dispatch("sanraletag/getSanralTransactionsById", {
          router: context.$router,
          id
        })
        .then(data => {

          context.GETTRANSACTIONLISTS = data;
         // context.getCommuterCardById(context,context.id);
          
        })
        .catch(err => {
          console.log("commuter details error: ", err);
        }).finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[1, 'desc']]
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }


          $("#data-table-trans").DataTable(options);
        });
    },
    getOtherContactsById: (context, id) => {
      context.$store
        .dispatch("sanraletag/getOtherContactsById", {
          router: context.$router,
          id
        })
        .then(data => {

          context.GETCONTACTSLIST = data;
         // context.getCommuterCardById(context,context.id);
          
        })
        .catch(err => {
          console.log("commuter details error: ", err);
        });
    },
    getVehiclesById: (context, id) => {
      context.$store
        .dispatch("sanraletag/getVehicllesById", {
          router: context.$router,
          id
        })
        .then(data => {

          context.GETVEHICLESLIST = data;
          //console.log("this is the commuter: ", data);
         // context.getCommuterCardById(context,context.id);
          
        })
        .catch(err => {
          console.log("commuter details error: ", err);
        });
    },
    ClickViewCards: (context) => {
      context.ISVIEWTRANSACTIONS = false;
    },
    ClickUnBlockCard: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$store
        .dispatch("taxivip/unblockCommuterCard", {
          router: context.$router,
          AddModel:{
            commutercard_id:value.item.id,
            updated_by: value.context.updatedby
          }
        })
        .then(data => {
          value.item.blocked = false;
        })
        .catch(err => {
          console.log("commuter card error: ", err);
        }).finally(() => {
          // $("#data-table-defaultcard").DataTable({
          //   responsive: false
          // });
        });
      }
    });
    },
    DeleteCard: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$store
        .dispatch("taxivip/deleteCommuterCard", {
          router: value.context.$router,
          id: value.item.id,
          deletedby: value.context.updatedby,
        })
        .then(data => {
          value.item.blocked = true;
        })
        .catch(err => {
          console.log("commuter card error: ", err);
        }).finally(() => {
          // $("#data-table-defaultcard").DataTable({
          //   responsive: false
          // });
        });
      }
    });
    },
    ClickBlockCard: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$store
        .dispatch("taxivip/blockCommuterCard", {
          router: context.$router,
          AddModel:{
            commutercard_id:value.item.id,
            updated_by: value.context.updatedby
          }
        })
        .then(data => {
          value.item.blocked = true;
        })
        .catch(err => {
          console.log("commuter card error: ", err);
        }).finally(() => {
          // $("#data-table-defaultcard").DataTable({
          //   responsive: false
          // });
        });
      }
    });
    },
    getCommuterCardsById: (context, id) => {
      context.$store
        .dispatch("taxivip/getcommuterCardsById", {
          router: context.$router,
          id
        })
        .then(data => {
          context.GETCOMMUTERVIPCARDS = data;
        })
        .catch(err => {
          console.log("commuter card error: ", err);
        }).finally(() => {
          // $("#data-table-defaultcard").DataTable({
          //   responsive: false
          // });
        });
    },

    ClickViewTransactions: (context, item) => {
      context.$wait.start("Transactions-" + item.id);
      context.$store
        .dispatch("taxivip/getCommuterCardTransactionsById", {
          id: item.cardnumber,
          skip: context.skip, 
          len: context.len,
          router: context.$router,
        })
        .then(data => {
          context.GETCOMMUTERCARDTRANSACTIONS = data
          //console.log("commuter transactions: ", data);
          context.ISVIEWTRANSACTIONS = true;
        })
        .catch(err => {
          console.log("commuter transactions error: ", err);
        }).finally(() => {
          context.$wait.end("Transactions-" + item.id);
          if(context.GETCOMMUTERCARDTRANSACTIONS.length === 0){
            $("#data-table-defaulttrans").DataTable({
              responsive: false
          });
          }
        });
    },
  },
  beforeMount() {
    this.getbyid(this.context, this.id);
    this.getVehiclesById(this.context, this.id);
    this.getOtherContactsById(this.context, this.id);
    this.getSanralTransactionsById(this.context, this.id);
    this.getSanralEtollsById(this.context, this.id);
    this.getSanralfeesById(this.context, this.id);
    //this.getCommuterCardTransactionsById(this.context,this.id);
    //this.getCommuterCardsById(this.context,this.id);
  },
  mounted: function() {
    App.init();
  }
};

</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>