<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Approvals'"
      :ActiveMenu="'Authorise'"
    />
    <!-- end #sidebar -->
    <transition name="bounce">
      <div id="content" class="content">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Approval Manager"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Approval"
          breadcrumbitem3="Authorise"
        />
        <!-- begin nav-pills -->

        <!-- begin nav-pills -->
        <ul class="nav nav-pills">
          <li class="nav-items">
            <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
              <span class="d-sm-none">Awaiting</span>
              <span class="d-sm-block d-none">Awaiting</span>
            </a>
          </li>
          <li class="nav-items">
            <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
              <span class="d-sm-none">Approved</span>
              <span class="d-sm-block d-none">Approved</span>
            </a>
          </li>
          <li class="nav-items">
            <a href="#nav-pills-tab-3" data-toggle="tab" class="nav-link">
              <span class="d-sm-none">Rejected</span>
              <span class="d-sm-block d-none">Rejected</span>
            </a>
          </li>
        </ul>
        <!-- end nav-pills -->

        <!-- begin tab-content -->
        <div class="tab-content">
          <!-- begin tab-pane -->
          <div class="tab-pane fade active show" id="nav-pills-tab-1">
            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>
            <div class="panel-body"> 
              <table id="data-table-pending" class="table width-full table-striped table-bordered">
                <thead>
                  <tr>
                    <th class="th-sm">Name</th>
                    <th class="th-sm">Details</th>
                    <th class="th-sm">Submitted By</th>
                    <th class="th-sm">Submitted Date</th>
                    <th class="th-sm">Profiles</th>
                    <th class="th-sm">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item) in GETALLPENDING" v-bind:key="item._id">
                    <td>{{item._taxi_driver_name || item._taxi_operator_name || item._taxi_vehicle_name }}</td>
                    <!-- <td>{{item._taxi_drive_id_number || item._taxi_operator_id_number || item._regnumber }}</td> -->
                    <td>{{item._approval_auth_code_name}}</td>
                    <td>{{item._added_by_names}}</td>
                    <td nowrap>{{item._date_added}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'taxioperatorprofileapproval', params: { id: item._taxi_operator_id, approvalid: item._id}}"
                        v-if="item._taxi_operator_id"
                        class="btn btn-sm btn-primary width-100 m-r-2"
                      >Operator</router-link>
                      <router-link
                        :to="{ name: 'taxidriverprofile', params: { id: item._taxi_driver_id}}"
                        v-if="item._taxi_driver_id"
                        class="btn btn-sm btn-warning width-100 m-r-2"
                      >Driver</router-link>
                      <router-link
                        :to="{ name: 'taxivehicleprofileapproval', params: { id: item._taxi_vehicle_id, approvalid: item._id}}"
                        v-if="item._taxi_vehicle_id"
                        class="btn btn-sm btn-yellow width-100 m-r-2"
                      >Vehicle</router-link>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80 m-r-2"
                        @click="OpenapproveModal(context, item)"
                        :disabled="$wait.is('approve-' + item._id)"
                      >
                      <v-wait v-bind:for="'approve-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Approve
                        </v-wait>
                      </button>

                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        @click="OpenrejectModal(context, item)"
                        :disabled="$wait.is('reject-' + item._id)"
                      ><v-wait v-bind:for="'reject-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Reject
                        </v-wait></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-pills-tab-2">
            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>
            <div class="panel-body"> 
              <table id="data-table-approved" class="table width-full table-striped table-bordered">
                <thead>
                  <tr>
                    <th class="th-sm">Name</th>
                    <th class="th-sm">Unique ID</th>
                    <th class="th-sm">Details</th>
                    <th class="th-sm">Submitted By</th>
                    <th class="th-sm">Submitted Date</th>
                    <th class="th-sm">Approved By</th>
                    <th class="th-sm">Approved Date</th>
                    <th class="th-sm">Comment</th>
                    <th class="th-sm">Profiles</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in GETALLAPPROVED" v-bind:key="item._id">
                    <td>{{item._taxi_driver_name || item._taxi_operator_name || item._taxi_vehicle_name }}</td>
                    <td>{{item._taxi_drive_id_number || item._taxi_operator_id_number || item._regnumber }}</td>
                    <td>{{item._approval_auth_code_name}}</td>
                    <td>{{item._added_by_names}}</td>
                    <td>{{item._date_added | formatToShortDateWithTime}}</td>
                    <td>{{item._approved_by_names}}</td>
                    <td>{{item._date_approved | formatToShortDateWithTime}}</td>
                    <td>
                      <span class="text-inverse">
                        <a
                          href="#"
                          data-toggle="tooltipApproved"
                          :title="item._comment"
                        >{{item._comment == null ? item._comment : item._comment.substring(0,20) + "..."}}</a>
                      </span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'taxioperatorprofile', params: { id: item._taxi_operator_id}}"
                        tag="button"
                        type="button"
                        v-if="item._taxi_operator_id"
                        class="btn btn-sm btn-primary width-100 m-r-2"
                      >Operator</router-link>
                      <router-link
                        :to="{ name: 'taxidriverprofile', params: { id: item._taxi_driver_id}}"
                        tag="button"
                        type="button"
                        v-if="item._taxi_driver_id"
                        class="btn btn-sm btn-warning width-100 m-r-2"
                      >Driver</router-link>
                      <router-link
                        :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_vehicle_id}}"
                        tag="button"
                        type="button"
                        v-if="item._taxi_vehicle_id"
                        class="btn btn-sm btn-yellow width-100 m-r-2"
                      >Vehicle</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-pills-tab-3">
            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>
            <div class="panel-body"> 
              <table id="data-table-rejected" class="table width-full table-striped table-bordered">
                <thead>
                  <tr>
                    <th class="th-sm">Name</th>
                    <th class="th-sm">Unique ID</th>
                    <th class="th-sm">Details</th>
                    <th class="th-sm">Submitted By</th>
                    <th class="th-sm">Submitted Date</th>
                    <th class="th-sm">Rejected By</th>
                    <th class="th-sm">Rejected Date</th>
                    <th class="th-sm">Comment</th>
                    <th class="th-sm">Profiles</th>
                    <th class="th-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in GETALLREJECTED" v-bind:key="item._id">
                    <td>{{item._taxi_driver_name || item._taxi_operator_name || item._taxi_vehicle_name }}</td>
                    <td>{{item._taxi_drive_id_number || item._taxi_operator_id_number || item._regnumber }}</td>
                    <td>{{item._approval_auth_code_name}}</td>
                    <td>{{item._added_by_names}}</td>
                    <td>{{item._date_added | formatToShortDateWithTime}}</td>
                    <td>{{item._rejected_by_names}}</td>
                    <td>{{item._rejected_date | formatToShortDateWithTime}}</td>
                    <td>
                      <span class="text-inverse">
                        <a
                          href="#"
                          data-toggle="tooltipRejected"
                          :title="item._comment"
                        >{{item._comment == null ? item._comment : item._comment.substring(0,20) + "..."}}</a>
                      </span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <router-link
                        :to="{ name: 'taxioperatorprofile', params: { id: item._taxi_operator_id}}"
                        tag="button"
                        type="button"
                        v-if="item._taxi_operator_id"
                        class="btn btn-sm btn-primary width-100 m-r-2"
                      >Operator</router-link>
                      <router-link
                        :to="{ name: 'taxidriverprofile', params: { id: item._taxi_driver_id}}"
                        tag="button"
                        type="button"
                        v-if="item._taxi_driver_id"
                        class="btn btn-sm btn-warning width-100 m-r-2"
                      >Driver</router-link>
                      <router-link
                        :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_vehicle_id}}"
                        tag="button"
                        type="button"
                        v-if="item._taxi_vehicle_id"
                        class="btn btn-sm btn-yellow width-100 m-r-2"
                      >Vehicle</router-link>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80"
                        :hidden="item.old_id"
                        @click="executeResubmitBtn(context,item,USERPROFILE.system_usersid)"
                        :disabled="$wait.is('executeResubmit' + item._id)"
                      >
                        <v-wait v-bind:for="'executeResubmit' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Re-Submit
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <div
            class="modal fade"
            id="approveModal"
            tabindex="-1"
            role="dialog"
            v-if="GETALLPENDING"
          >
            <div class="modal-dialog width-full" role="document">
              <div class="modal-content">
                <form>
                  <div class="modal-header">
                    <h4 class="text-center">Approve</h4>
                  </div>
                  <div class="modal-body width-full">
                    <div class="col-md-12">
                      <!-- begin form-group row -->

                      <!-- begin form-group row -->
                      <div class="form-group row m-b-10">
                        <label class="col-md-3 text-md-right col-form-label">Comment</label>
                        <div class="col-md-9">
                          <textarea
                            class="textarea form-control"
                            v-model="comment"
                            placeholder="Enter comment..."
                            rows="10"
                          ></textarea>
                        </div>
                      </div>
                      <!-- end form-group row -->
                    </div>
                  </div>
                  <div class="modal-footer">
                    <input
                      type="button"
                      class="btn btn-default"
                      data-dismiss="modal"
                      value="Cancel"
                    />
                    <button
                      type="button"
                      class="btn btn-sm btn-success width-80"
                      @click="approve(context,GETSELECTED,USERID,comment)"
                      :disabled="$wait.is('executeapproval')"
                    >
                      <v-wait v-bind:for="'executeapproval'">
                        <template slot="waiting">
                          <i class="fas fa-spinner fa-spin"></i>
                        </template>
                        Approve
                      </v-wait>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Edit Modal HTML START-->

          <div class="modal fade" id="rejectModal" tabindex="-1" role="dialog" v-if="GETALLPENDING">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <form>
                  <div class="modal-header">
                    <h4 class="text-center">Reject</h4>
                  </div>
                  <div class="modal-body">
                    <div class="col-md-12">
                      <!-- begin form-group row -->

                      <!-- begin form-group row -->
                      <div class="form-group row m-b-10">
                        <label class="col-md-3 text-md-right col-form-label">Comment</label>
                        <div class="col-md-9">
                          <textarea
                            class="textarea form-control"
                            v-model="comment"
                            placeholder="Enter comment..."
                            rows="12"
                          ></textarea>
                        </div>
                      </div>
                      <!-- end form-group row -->
                    </div>
                  </div>
                  <div class="modal-footer">
                    <input
                      type="button"
                      class="btn btn-default"
                      data-dismiss="modal"
                      value="Cancel"
                    />
                    <button
                      type="button"
                      class="btn btn-sm btn-danger width-80"
                      @click="reject(context,GETSELECTED,USERID,comment)"
                      :disabled="$wait.is('executereject')"
                    >
                      <v-wait v-bind:for="'executereject'">
                        <template slot="waiting">
                          <i class="fas fa-spinner fa-spin"></i>
                        </template>
                        Reject
                      </v-wait>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- end tab-content -->
      </div>
    </transition>
  </div>
</template>

<script>
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("approvals");

export default {
  name: "AuthorizeApprovalRequest",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      id: this.$route.params.id,
      GETALLPENDING: [],
      GETALLAPPROVED: [],
      GETALLREJECTED: [],
      GETSELECTED: {},
      comment: "",
      skip: 0,
      length: 10000000,
      dataLoaded: false,
      // canApproveOpperator: this.$store.getters["auth/GETUSERROLES"][13]._code,
      // canApproveDriver: this.$store.getters["auth/GETUSERROLES"][6]._code,
      // canApproveOpperatorVehicle: this.$store.getters["auth/GETUSERROLES"][5]._code,
    };
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    //...mapGetters(["GETALLPENDING", "GETALLAPPROVED", "GETALLREJECTED"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  beforeMount() {
    this.getPending(this.context);
  },
  methods: {
    ...mapActions([
      "getAllApproved",
      "getAllpending",
      "getAllRejected",
      "executeapproval",
      "executereject",
      "executeResubmit",
    ]),
    OpenrejectModal: (context, item) => {
      context.GETSELECTED = item;
      $("#rejectModal").modal("show");
    },
    OpenapproveModal: (context, item) => {
      context.GETSELECTED = item;
      $("#approveModal").modal();
    },

    executeResubmitBtn: (context, item, added_by) => {
      context.$Notify
        .YesNo(false, { context, item, added_by })
        .then((value) => {
          if (value) {
            value.context.$wait.start("executeResubmit" + item._id);
            value.context
              .executeResubmit({
                item: value.item,
                router: value.context.$router,
                added_by: value.added_by,
              })
              .then(() => {
                value.context.$wait.end("executeResubmit" + item._id);
                value.context.$Notify.Success(
                  "Request Re-submited Successfully!",
                  ""
                );
              })
              .catch((error) => {
                value.context.$wait.end("executeResubmit" + item._id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    reject: (context, item, rejected_by, comment) => {
      
      context.$Notify
        .YesNo(false, {
          context,
          item,
          rejected_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: item._id,
        })
        .then((value) => {
          if (value) {

            $("#rejectModal").modal("hide");
            value.context.$wait.start(
              "reject-" + item._id
            );

            //value.context.$wait.start("executereject");
            value.router = value.context.$router;
            value.context
              .executereject({
                _dataObj: value,
              })
              .then((data) => {
                context.comment = "";
                //console.log("rejected successfully", data);
                //$("#rejectModal").modal("hide");
                //value.context.$wait.end("executereject");
                value.context.$Notify.Toast("Rejected!",null);
                value.context.$wait.end("approve-" + item._id);
                var index = value.context.GETALLPENDING.indexOf(item);
                value.context.GETALLPENDING.splice(index, 1);

              })
              .catch((error) => {
                //value.context.$wait.end("executereject");
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                
                value.context.$wait.end("executereject");
              });
          }
        });
    },
    approve: (context, item, approved_by, comment) => {
      
      context.$Notify
        .YesNo(false, {
          context,
          item,
          approved_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: item._id,
        })
        .then((value) => {
          if (value) {
            
            //value.context.$wait.start("executeapproval");
            $("#approveModal").modal("hide");
            value.context.$wait.start(
              "approve-" + item._id
            );
            value.router = value.context.$router;
            value.context
              .executeapproval({
                _dataObj: value,
              })
              .then(() => {
                context.comment = "";

                

                value.context.$wait.end("reject-" + item._id);

                var index = value.context.GETALLPENDING.indexOf(item);
                value.context.GETALLPENDING.splice(index, 1);
                //value.context.$wait.end("executeapproval");
                value.context.$Notify.Toast("Approved Successfully!",true,false);
                //value.context.getPending(value.context);
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {
                $("#approveModal").modal("hide");
              });
          }
        });
    },
    getApproved: (context) => {
      const router = context.$router;
      context.GETALLAPPROVED = [];
      context.dataLoaded = false;

      let payload = {
        taxiassociationid: context.taxiassociationid,
        userid: context.USERID,
        skip: context.skip,
        length: context.length,
      };

      context
        .getAllApproved({ router, payload })
        .then((data) => {
          context.GETALLAPPROVED = data;
          console.log("approved: ", data);
        })
        .catch((err) => {
          console.log("Error: ", err);
        })
        .finally(() => {
          context.dataLoaded = true;
          $("#data-table-approved").DataTable({
            responsive: true,
          });
        });
    },
    getPending: (context) => {
      
      const router = context.$router;
      context.GETALLPENDING = [];
      //context.dataLoaded = false;
      let payload = {
        taxiassociationid: context.taxiassociationid,
        userid: context.USERID,
      };

      context
        .getAllpending({ router, payload })
        .then((data) => {

          if(context.GETALLPENDING.length === 0) {
            context.$Notify.Success("No Data Found","");
          }

        })
        .finally(() => {
          
          if(context.GETALLPENDING.length > 0 && !context.dataLoaded)
          {
          $("#data-table-pending").DataTable({
                      responsive: true,
          });
          }
          context.dataLoaded = true;
        });
    },
    getRejected: (context) => {
      const router = context.$router;
      context.GETALLREJECTED = [];
      context.dataLoaded = false;
      let payload = {
        taxiassociationid: context.taxiassociationid,
        userid: context.USERID,
        skip: context.skip,
        length: context.length,
      };
      context
        .getAllRejected({ router, payload })
        .then((data) => {
          console.log("rejected found:", data);
          context.GETALLREJECTED = data;
        })
        .catch((error) => {
          console.log("These are the rejects: ", error.message);
        })
        .finally(() => {
          context.dataLoaded = true;
          $("#data-table-rejected").DataTable({
            responsive: true,
          });
        });
    },
  },
  mounted: function () {
    try {
      window.PushManager.pushManagerHooks.addEventListener(
      "IncomingMessage",
      (e) => {
        console.log('Instance fired "something".', e);
      }
    );
    } catch (error) {
      
    }
    

    $('[data-toggle="tooltipApproved"]').tooltip();
    $('[data-toggle="tooltipRejected"]').tooltip();

    $(".nav-pills a").data("context", this.context);

    $(".nav-pills a").on("shown.bs.tab", function (event) {
      const context = $(".nav-pills a").data("context");
      var x = $(event.target).text(); // active tab
      var y = $(event.relatedTarget).text(); // previous tab

      if (x === "ApprovedApproved") {
        
        context.getApproved(context);
      } else if (x === "AwaitingAwaiting") {
        
        context.getPending(context);
      } else if (x === "RejectedRejected") {
        
        context.getRejected(context);
      }
    });

    // $("#data-table-approved").DataTable({
    //   responsive: true
    // });

    // $("#data-table-pending").DataTable({
    //   responsive: true
    // });

    App.init();
  },
};
</script>