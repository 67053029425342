<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageUserInfo'"
      :ActiveMenu="'SystemUsers'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Manage System Users"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="System Users"
        breadcrumbitem3="Manage"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">System Users</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link to="/QuickSetup/AddSystemUser" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add System Users
                </router-link>
                <!-- <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-primary"
                  href="javascript:;"
                  @click="clearAdded(GETSELECTED)"
                  :disabled="!USERPROFILE.is_system_admin"
                >
                  <i class="fa fa-user-plus"></i>
                  Add Admin User
                </a> -->
              </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-users" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th class="text">UserName</th>
                    <th class="text">First Name</th>
                    <th class="text">Surname</th>
                    <th class="text">ID Number</th>
                    <th class="text">Mobile Number</th>
                    <th class="text">Status</th>
                    <th class="text">Group</th>
                    <th class="text">Last Updated By</th>
                    <th class="text">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETALL"
                    v-bind:key="item._id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                  <td>{{item._username}}</td>
                    <td>{{item._firstname}}</td>
                    <td>{{item._surname}}</td>
                    <td>{{item._id_number}}</td>
                    <td>{{item._mobile_number}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._deleted_by }"
                        v-if="item._deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                        v-if="!item._deleted_by"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item._usergroups_name}}</td>

                    <td>{{item._updateby_name || item._addedby_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      
                      <router-link
                        :to="{ name: 'systemuserprofile', params: { id: item._id }}"
                        type="button"
                        @click="getitem(item.id)"
                        class="btn btn-sm btn-default width-60 m-r-2"
                      >View</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-warning width-100 m-r-2"
                        v-if="item._account_number == null"
                        :disabled="$wait.is('activate-' + item._id)"
                        @click="createAccount(context,CREATEACCOUNT,item._id)"
                      >
                        <v-wait v-bind:for="'activate-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Create Account
                        </v-wait>
                      </button>

                      <router-link
                        :to="{ name: 'updateuserphotos', params: { id: item._id }}"
                        type="button"
                        @click="getitem(item.id)"
                        class="btn btn-sm btn-primary width-100 m-r-2"
                      >Capture Photos</router-link>
                      
                    
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-80 m-r-2"
                        v-if="!item._is_active && isSystemAdmin"
                        :disabled="$wait.is('activate-' + item._id)"
                        @click="activateUser(context,item._id)"
                      >
                        <v-wait v-bind:for="'activate-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Activate
                        </v-wait>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80 m-r-2"
                        v-if="item._is_active && isSystemAdmin"
                        :disabled="$wait.is('deactivate-' + item._id)"
                        @click="deactivateSystemuserUser(context, item._id)"
                      >
                        <v-wait v-bind:for="'deactivate-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Deactivate
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Edit Modal HTML START-->
              <div id="ViewModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">View</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Title</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._title"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Gender</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._gender"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">First Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._firstname"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Surname</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._surname"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">ID Number</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._id_number "
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Mobile Number</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._mobile_number"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Username</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._username"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Taxi Association</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._taxi_associations_abbr"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Group</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                :value="GETSELECTED._usergroups_name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Added By</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._addedby_name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Date Added</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._date_added || formatToShortDateTime"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Last Updated By</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._updateby_name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Last Updated Date</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._date_updated"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Deleted By</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._deletedby_name"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Deleted Date</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder
                                class="form-control"
                                :value="GETSELECTED._date_deleted"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Is SysAdmin</label>
                            <div
                              class="checkbox checkbox-css"
                              :class="{'is-valid':GETSELECTED._is_system_admin}"
                            >
                              <input
                                type="checkbox"
                                id="cssCheckbox1"
                                :checked="GETSELECTED._is_system_admin"
                                disabled
                              />
                              <label
                                for="cssCheckbox1"
                              >{{GETSELECTED._is_system_admin ? 'True' : 'False' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div
                              class="checkbox checkbox-css"
                              :class="{'is-valid':GETSELECTED._is_active}"
                            >
                              <input
                                type="checkbox"
                                id="cssCheckbox1"
                                :checked="GETSELECTED._is_active"
                                disabled
                              />
                              <label v-if="GETSELECTED._deleted_by" for="cssCheckbox1">Deleted</label>
                              <label
                                v-if="!GETSELECTED._deleted_by"
                                for="cssCheckbox1"
                              >{{GETSELECTED._is_active == true ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Edit Modal HTML START-->
              <div id="EditModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Edit Title</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                placeholder="Name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="BillingTypeName"
                                id="billing_type_name"
                                class="form-control"
                                v-model.lazy="GETSELECTED._description"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Status</label>
                            <div class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                id="cssCheckbox1edit"
                                v-model="GETSELECTED._is_active"
                                :checked="GETSELECTED._is_active"
                              />
                              <label
                                v-if="!GETSELECTED._deleted_by"
                                for="cssCheckbox1edit"
                              >{{GETSELECTED._is_active ? 'Active' : 'Disabled' }}</label>
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="edited(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('edited')"
                        >
                          <v-wait v-bind:for="'edited'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Save
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Edit Modal HTML START-->

              <!-- Add Modal HTML START-->
              <div id="AddModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Title</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Name"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED._name"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->

                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Description</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                placeholder="Enter Description"
                                class="form-control"
                                minlength="3"
                                v-model="GETSELECTED._description"
                              />
                            </div>
                          </div>
                          <!-- end form-group row -->
                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        />
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="add(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("systemusers");

export default {
  name: "ManageUserGroups",
  data: function () {
    return {
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      GETALL: {},
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      userid: this.$store.getters["auth/USERPROFILE"].system_usersid,
      CREATEACCOUNT:{
        accounttype_code: "TAT",
        systemusers_id: 0,
        accountstatus_code: "ACT",
        added_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      ACTIVATEUSERS: {
        systemuser_id: 0,
        activated_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      DEACTIVATEUSERS: {
        systemuser_id: 0,
        deactivated_by: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      GETSELECTED: Object.assign(
        {},
        this.$store.getters["systemusers/GETALL"]
      )[0],
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {},
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    //...mapGetters(["GETALL"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
    ]),
    getitem: (id) => {
      alert("this is the id: ", id);
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    clearAdded: (item) => {
      for (var propName in item) {
        item[propName] = undefined;
      }
    },
    add: (context, item, addedby) => {
      item._is_active = true;
      item._added_by = addedby;
      item._addedby_name = context.LoggedInUserFullName;
      item._fk_taxi_association_id = context.taxi_association_id;

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("systemusers/add", {
              _dataObj: value,
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    setAdd(val) {
      this.$store
        .dispatch("systemusers/add", {
          _dataObj: val,
        })
        .then(() => {});
    },

    // getByTaxiAssId: context => {
    //   context.$store
    //     .dispatch("systemusers/getByTaxiAssId", {
    //       _dataObj: {
    //         router: context.$router,
    //         id: context.taxi_association_id
    //       }
    //     })
    //     .then(() => {

    //     });
    // },

    get: (context) => {
      
      context
        .getByTaxiAssId({
            router: context.$router,
            id: context.taxi_association_id,
            userid: context.userid,
        })
        .then((data) => {
          
          context.GETALL = data;
        })
        .finally(() => {
          $("#data-table-users").DataTable({
            responsive: false,
          });
        });
    },

    createAccount: (context,item,id) =>{
      item.systemusers_id = id;
      context.$Notify
        .YesNo(false, { context, item, id })
        .then((value) => {
          if (value) {
            // value.context.$wait.start("edited");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("systemusers/addSystemuserAccount", {
                addModel: value.item,
                router: value.router
              })
              .then(() => {
                
                value.context.$Notify.Success("Account Created Successfully", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.$wait.end("edited");
              });
          }
        });
    },

    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      item._fk_taxi_association_id = context.taxi_association_id;
      context.$Notify
        .YesNo(false, { context, item, updatedby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("edited");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("systemusers/update", {
                _dataObj: value,
              })
              .then(() => {
                $("#EditModal").modal("hide");
                value.context.$wait.end("edited");
                value.context.$Notify.Success("Successfully Updated!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.$wait.end("edited");
              });
          }
        });
    },
    activateUser: (context, item) => {
      context.ACTIVATEUSERS.systemuser_id = item;
      context.$Notify
        .YesNo(false, { context, item: context.ACTIVATEUSERS })
        .then((value) => {
          
          if (value) {
            value.context.$wait.start("activate-" + "");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("systemusers/activateSystemuserUser", {
                _addModel: value.item,
                router: value.router,
              })
              .then((data) => {
                console.log("user has been activated", data);
                context.$wait.end("activate-" + "");
                context.$Notify.Success("Activated Successfully!", "");
                context.get(context);
              })
              .catch((error) => {
                console.log("activate user errors: ", error);
                context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        });
    },

    deactivateSystemuserUser: (context, item) => {
      context.DEACTIVATEUSERS.systemuser_id = item;
      context.$Notify
        .YesNo(false, { context, item: context.DEACTIVATEUSERS })
        .then((value) => {
          
          if (value) {
            value.context.$wait.start("deactivate-" + "");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("systemusers/deactivateSystemuserUser", {
                _addModel: value.item,
                router: value.router,
              })
              .then((data) => {
                console.log("user has been activated", data);
                context.$wait.end("deactivate-" + "");
                context.$Notify.Success("Deactivated Successfully!", "");
                context.get(context);
              })
              .catch((error) => {
                console.log("deactivate user errors: ", error);
                context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  mounted: function () {
    this.get(this.context);

    App.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

