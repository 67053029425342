/* eslint-disable import/no-named-as-default-member */
import VueRecord from "@codekraft-studio/vue-record";
import moment from "moment";
import Vue from "vue";
import VueCurrencyInput from "vue-currency-input";
import UUID from "vue-uuid";
import VueWait from "vue-wait"; // https://github.com/f/vue-wait
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import { API, API_FIN, Notify, Toolkit, TPAY_API } from "./Service";
import store from "./store/store";

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: "ZAR" },
};

Vue.config.productionTip = false;
Vue.filter("formatToShortDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YY H:mm");
  }
});
Vue.filter("currency", function(amount) {
  if (amount || amount === 0) {
    const amt = Number(amount).toFixed(2);
    return (
      (amt && amt.toLocaleString(undefined, { maximumFractionDigits: 2 })) ||
      "00"
    );
  }
});
Vue.filter("currencywithSymbol", function(amount) {
  if (amount || amount === 0) {
    const amt = Number(amount).toFixed(2);
    return (
      ("R" + amt &&
        "R" + amt.toLocaleString(undefined, { maximumFractionDigits: 2 })) ||
      "00"
    );
  }
});

Vue.filter("numberwith", function(
  amount,
  decimalCount = 0,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      "" +
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(0)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
});


Vue.filter("newcurrencywithSymbol", function(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      "R " +
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
});

Vue.filter("newcurrency", function(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
});

Vue.filter("formatToLongDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY H:mm");
  }
});
Vue.filter("formatToLongDateTimeWithSeconds", function(value) {
  if (value) {
    return moment(String(value)).format("YYYY/MM/DD H:mm:ss");
  }
});
Vue.filter("formatToTime", function(value) {
  if (value) {
    return moment(String(value)).format("H:mm");
  }
});
Vue.filter("formatToShortDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD MMM YYYY");
  }
});
Vue.filter("formatToShortDateWithTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD MMM YYYY H:mm:ss");
  }
});
Vue.filter("formatToMonthDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD MM YYYY");
  }
});
Vue.filter("formatMonth", function(value) {
  if (value) {
    return moment(String(value)).format("MMMM");
  }
});
Vue.filter("formatToHumanize", function(value) {
  if (value) {
    return moment(String(value)).from();
  }
});

Vue.filter("formatToHumanize2", function(value1, value2) {
  if (value2) {
    return moment(String(value1)).from(value2);
  }
});

Vue.filter("HumanizeMobile", function(value) {
  if (value && typeof value === "string") {
    if (value.substr(0, 2) == "27") return "0" + value.substr(2, 9);
    else return value;
  }
  return value;
});

Vue.filter("striphtml", function(value) {
  var span = document.createElement("span");
  span.innerHTML = value;
  var text = span.textContent || span.innerText || "";
  return text;
});

Vue.filter("parseBoolean", function(value) {
  if (value && typeof value === "string") {
    return value === "true";
  }
  return value;
});

Vue.filter("UPPER", function(value) {
  if (value && typeof value === "string") {
    return value.toUpperCase();
  }
  return value;
});

Vue.filter("Round", function(value) {
  if (value && typeof value === "string") {
  return Math.round(value * 100) / 100;
  }
  return  Math.round(value * 100) / 100;
});



// This callback runs before every route change, including on page load.
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  // eslint-disable-next-line no-undef
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  // eslint-disable-next-line no-undef
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      // eslint-disable-next-line no-undef
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    // eslint-disable-next-line no-undef
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

Vue.use(Vuex);
Vue.use(VueWait);
Vue.use(API);
Vue.use(API_FIN);
Vue.use(TPAY_API);
Vue.use(Notify);
Vue.use(UUID);
Vue.use(Toolkit);
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(VueRecord);

Vue.directive("select2", {
  inserted(el) {
    $(el).on("select2:select", () => {
      const event = new Event("change", { bubbles: true, cancelable: true });
      el.dispatchEvent(event);
    });

    $(el).on("select2:unselect", () => {
      const event = new Event("change", { bubbles: true, cancelable: true });
      el.dispatchEvent(event);
    });
  },
});

Vue.directive("datepicker", {
  inserted(el, binding, vNode) {
    
    $(el).on("changeDate", (e) => {
      const event = new Event("change", { bubbles: true, cancelable: true });
      el.value = e.format(0);
      el.dispatchEvent(event);
    });
  },
});

new Vue({
  router,
  store,
  wait: new VueWait({
    useVuex: true, // You must pass this option `true` to use Vuex
    registerComponent: true,
    registerDirective: true,
  }),
  render: (h) => h(App),
}).$mount("#app");
